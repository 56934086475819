$env: production;
@import '@/styles/_component-base.styles.scss';

.container {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.label {
    cursor: pointer;
    margin-right: spacer(xs);
}

.background {
    cursor: pointer;
    position: relative;
    width: 48px;
    height: 28px;
    border-radius: 100px;
    background-color: color(contrast, 4);
    transition: background-color 0.2s ease-in-out;

    &[data-state='checked'] {
        background-color: color(accent, blue);
    }
}

.indicator {
    cursor: pointer;
    position: absolute;
    top: 4px;
    left: 4px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #fff;
    transition: transform 0.2s ease-in-out;

    .background[data-state='checked'] & {
        transform: translateX(20px);
    }
}
