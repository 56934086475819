$env: production;
@import '@/styles/_component-base.styles.scss';

.checkBoxGroup {
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: spacer(base) spacer(2dot5xl);
    @include breakpoint(lg) {
        padding: spacer(base) spacer(base) spacer(md);
    }

    input:focus + label::before{
        border: 2px solid color(accent, text);
    }

    label{
        padding-left: spacer(lg);
        min-height: 20px;
        &::before{
            top: 0;
            width: spacer(md);
            height: spacer(md);
        }
        &::after{
            top: calc(spacer(sm)/2);
            left: spacer(2xs);
            width: spacer(sm);
            height: calc(spacer(sm)/2);
            border-left: 2px solid color(text, base);
            border-bottom: 2px solid color(text, base);
        }
    }
    span{
        text-align: left;
        font-weight: 300;
        line-height: normal;
        white-space: nowrap;
        font-size: set-fontSize(14);
        margin-left: spacer(2xs) + spacer(3xs);
    }

    .filterCheckbox{
        input:checked + label::before{
            background-color: color(contrast, white);
        }

        input:checked + label span{
            font-weight: normal;
        }

        input:focus + label::before{
            border: 2px solid #b17b55;
        }
    }
}

.formCheckbox {
    pointer-events: none;
}
