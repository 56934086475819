$env: production;
@import '@/styles/_component-base.styles.scss';

.noProductsTile {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
    width: 100%;
}

// TODO: move to product tile bit component
.title,
.subtitle {
    text-align: left;
}

.detailModalContainer span[role="tooltip"] aside {
    @include only-breakpoint(md) {
        right: unset;
    }
}

.closeButton {
    @include breakpoint(md) {
        position: absolute;
        cursor: pointer;
        z-index: 4001;
        top: 1.5rem;
        right: calc(4rem / 2);
        transform: translate(50%, -50%);
        width: 1.25rem;
        height: 1.25rem;
    }
}

.clearButton{
    min-width: 60px;
    cursor: pointer;
    text-decoration: underline;
    font-size: set-fontSize(14);
    font-weight: normal;
    color: color(darkContrast, 2);
}

.addToCartSection a {
    width: 100%;
}

.detailModalSelectors {
    label {
        height: 100%;

        div {
            min-height: 56px;
            font-size: set-fontSize(14);

            @include breakpoint(md) {
                min-height: 36px;
                padding: spacer(xs)
            }
        }
    }
}

.plpItemsGrid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: spacer(xs);
    padding-left: spacer(sm);
    padding-right: spacer(sm);
    margin-bottom: spacer(xl);
    width: 100%;

    @include breakpoint(lg) {
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: spacer(md);
        padding: spacer(md);
    }
}
