$env: production;
@import '@/styles/_component-base.styles.scss';

.pageheader {
    margin-bottom: spacer(2xl);

    @include breakpoint(lg) {
        margin-bottom: spacer(3dot75xl);
    }
}

.productTileContainer{
    @include between-breakpoint(xs, sm){
        margin-bottom: 0;
    }
}

.resultsCount{
    margin-bottom: spacer(base);
    font-size: fontSize(14) ;
    font-weight: 400;
}

.loadMoreBtn{
    width: 100%;
    max-width: 348px;
    margin-bottom: spacer(2xl);
}
