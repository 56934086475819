$env: production;
@import '@/styles/_component-base.styles.scss';

.valuePropCard {
    break-inside: avoid;
    margin-bottom: spacer(base);

    .content {
        background-color: color(state, base);
        background: linear-gradient(180deg, color(contrast, white) 3.18%, #fbfaf8 100%), color(contrast, white);
        border-radius: 5px;
        box-shadow: 5px 5px 0px rgba(193, 163, 139, 0.2);
        padding: spacer(lg);

        &-heading {
            display: flex;
            justify-content: flex-start;
            flex-direction: row;
            margin-bottom: spacer(sm);

            &-title {
                align-self: center;
                display: flex;
                font-size: set-fontSize(20);
                font-weight: 400;
                line-height: 25px;
                text-align: left;
            }
        }

        &-description {
            p {
                font-size: set-fontSize(16);
                font-weight: 300;
                line-height: 26px;
                text-align: left;
            }
        }
    }
}

.valuePropCards {
    background-color: color(action, darkActive);

    .cardContent {
        height: 100%;
    }

    .cardHeading {
        height: 75px;
    }

    .cardTitle {
        font-size: set-fontSize(20);
        font-family: $fontFamily-serif;

        @include breakpoint (md) {
            font-size: set-fontSize(18);
        }
    }

    .svg {
        overflow: visible;
        align-self: center;
        flex: 0 0 auto;
        margin-right: spacer(base);
        stroke: color(action, base);
        height: 56px;
        width: auto;

        &-world {
            height: 46px;
            width: auto;
            // need 36px to offset the diference in viewbox dimension between icons
            margin-right: calc(spacer(2xl) + 0.25rem);
        }
    }
}
