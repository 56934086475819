$env: production;
@import '@/styles/_component-base.styles.scss';

.dropdownsContainer{
    display: flex;
}

.accordionSet {
    @include breakpoint(lg) {
        ul {
            display: flex;
        }
    }
}

.accordion {
    padding: spacer(md) spacer(md) spacer(xl);
    border-top: solid 1px #e9e7e5;

    @include breakpoint(lg){
        padding: 0;
        border-top: none;
    }
}

.accordionTitleContainer {
    padding: 0;
    padding-right: spacer(lg);
    margin-bottom: spacer(sm);
}

.accordionTitle {
    font-weight: normal;
    text-transform: uppercase;
    font-size: set-fontSize(14);
    color: color(text, base);

    @include breakpoint(md) {
        letter-spacing: 0.89px;
    }

    @include breakpoint(lg) {
        font-size: set-fontSize(16);
    }
}

.accordionIconContainer{
    margin-left: 0;
    padding-left: 0;
}

.chevronIcon{
    stroke-width: 1.5px;
    margin-left: spacer(xs);
    margin-bottom: spacer(3xs);
}

.accordionContent{
    padding: 0;

    ul, li {
        list-style: none;
        margin: 0;
    }
}