$env: production;
@import '@/styles/_component-base.styles.scss';

.filterSection {
    display: flex;
    margin-bottom: spacer(2xl);
    @include breakpoint(lg){
        justify-content: space-between;
        margin-bottom: spacer(base)
    }
}

.sortingFilterContainer {
    @include breakpoint(lg) {
        flex-basis: auto;
    }
}

.sortingDropdownTitle{
    min-width: 100px;
    margin: 0;
}

.sortingDropdownPanel{
    @include breakpoint(lg){
        right: 0;
    }
    
    &:hover > .sortingOptionButton {
        &.optionSelected{
            font-weight: normal;
            background-color: color(contrast, white);

            &:hover{
                font-weight: normal;
                background-color: color(state, hover);
            }
        }

    }
}

.sortingOptionButton{
    width: 100%;
    padding: spacer(xs) spacer(3xl) spacer(xs) spacer(md);
    text-align: left;
    font-weight: 300;
    white-space: nowrap;
    letter-spacing: normal;
    color: color(text, base);
    cursor: pointer;
    font-size: set-fontSize(14);
    font-family: $fontFamily-sans-serif;
    
    &:hover {
        background-color: color(state, hover);
    }
}

.optionSelected{
    font-weight: normal;
    background-color: color(state, hover);
}

.filtersResults{
    margin-bottom: spacer(base);

    @include breakpoint(md) {
        display: flex;
        min-height: 38px;
        align-items: center;
    }
}

.totalResults {
    font-weight: normal;
    text-align: left;
    min-width: 100px;
    font-size: set-fontSize(14);

    @include breakpoint(md){
        font-size: set-fontSize(16)
    }
}

.clearButton{
    min-width: 60px;
    cursor: pointer;
    text-decoration: underline;
    font-size: set-fontSize(14);
    font-weight: normal;
    color: color(darkContrast, 2);
}

.appliedFiltersContainer{
    text-align: left;
}

.appliedFilterButton{
    border-radius: 2px;
    min-height: 30px;
    margin: spacer(2xs) spacer(xs) spacer(2xs) 0;
    padding: spacer(2xs) spacer(xs);
    color: color(text, base);
    font-size: set-fontSize(14);
    background-color: #f0efee;
    cursor: pointer;
}

.buttonCloseIcon{
    stroke-width: 1;
    margin-left: spacer(xs);
    stroke: color(text, base);
    transform: translateY(2px);
}

