$env: production;
@import '@/styles/_component-base.styles.scss';

.gallery {
    display: grid;
    grid-gap: spacer(3xs);

    &--right {
        grid-template-rows: repeat(3, 92px);
        grid-template-columns: repeat(4, 169px);
    }

    &--bottom {
        grid-template-rows: 100px 100px 100px 116px;
        grid-template-columns: repeat(3, 178px);
    }

    .item {
        cursor: pointer;
        background-color: color(contrast, 4);

        &--selected {
            grid-row-start: 1;
            grid-row-end: 4;
            grid-column-start: 1;
            grid-column-end: 4;
        }
    }
}

.galleryCarousel {
    .item {
        width: 100%;
        height: 100%;
        cursor: grab;
    }

    .itemWrapper {
        width: 300px;
        height: 240px;
        margin: spacer(3xs);
        background-color: color(contrast, 4);
    }
}

.image {
    display: block;
    width: 100%;
    height: 100%;

    img {
        height: inherit;
        width: inherit;
        object-fit: cover;
    }

    @include breakpoint(md) {
        filter: brightness(70%);

        &:hover {
            filter: brightness(98%);
        }

        &--selected {
            filter: brightness(100%);
        }
    }
}
