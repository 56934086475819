$env: production;
@import '@/styles/_component-base.styles.scss';

.dropdownContainer {
    position: relative;
    font-size: set-fontSize(16);
}

.dropdownPanel {
    position: absolute;
    z-index: 2999;
    border: solid 1px color(contrast, 3);
    background-color: color(contrast, white);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    overflow: hidden;
    transition: all 0.3s ease-out;

    @include breakpoint(lg){
        top: calc(spacer(2xl) + spacer(2xs))
    }
}

.dropdownContainer[aria-expanded="true"] .dropdownPanel {
    visibility: visible;
    max-height: 200px;
    opacity: 1;

}

.dropdownContainer[aria-expanded="false"] .dropdownPanel {
    visibility: hidden;
    opacity: 0;
    max-height: 0px;
}

.dropdownTitle {
    text-align: left;
    margin-right: spacer(lg);
    margin-bottom: spacer(sm);
    text-transform: uppercase;
    font-weight: normal;
    cursor: pointer;

    &Border {
        @include breakpoint(lg) {
            padding: spacer(xs) spacer(sm);
            border: 1px solid color(contrast, 3);

            &:hover, &:focus, &[aria-expanded="true"] {
                background-color: color(state, hover);
            }
        }
    }

    @include between-breakpoint(xs, md){
        width: 100%;
        margin-bottom: 0;
        font-size: set-fontSize(14);
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 2px;
        border: solid 1px #463f38;
        background-color: #f6f5f3;
    }

    @include breakpoint(md) {
        letter-spacing: 0.89px;
    }
}

.chevronIcon{
    stroke-width: 1.5px;
    margin-left: spacer(xs);
    margin-bottom: spacer(3xs);
}

.label {
    @include between-breakpoint(xs, md) {
        display: none;
    }

    text-align: left;
    line-height: 20px;
    font-weight: 400;
    letter-spacing: 0.25px;
    margin-right: spacer(sm);
    text-transform: uppercase;
    font-size: set-fontSize(16);
    font-family: $fontFamily-sans-serif;
}
