$env: production;
@import '@/styles/_component-base.styles.scss';

.label {
    text-align: left;
    text-transform: uppercase;
    font-weight: normal;
    cursor: pointer;
    
    @include breakpoint(md) {
        letter-spacing: 0.89px;
    }

    @include breakpoint(lg) {
        font-size: set-fontSize(16);
        margin-bottom: spacer(sm);
    }
}

.switch {
    @include breakpoint(lg) {
        margin-bottom: spacer(sm);
    }
}