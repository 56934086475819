$env: production;
@import '@/styles/_component-base.styles.scss';

.modalContainer{
    justify-content: space-between;
    @include breakpoint(md){
        width: 400px;
        height: 700px;
    }
}

.modalContentWrapper{
    padding: 0;
}

.modalBtn{
    width: 100%;
    min-width: 100px;
    font-size: calc(14 / 16 * 1rem);
    text-transform: uppercase;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
    background-color: #f6f5f3;
    color: color(text, base);
    outline: deepskyblue;

    @include breakpoint(md) {
        letter-spacing: 0.89px;
    }
}

.modalTitle{
    font-weight: 600;
    text-align: center;
    padding: spacer(xs) 0;
    letter-spacing: 0.89px;
    color: color(text, base);
    text-transform: uppercase;
    font-size: set-fontSize(16);
    border-bottom: solid 1px #e9e7e5;
    font-family: $fontFamily-sans-serif;
    background-color: color(contrast, white);
}

.closeBtn{
    position: absolute;
    top: spacer(lg);
    right: spacer(2xl);
    width: spacer(md);
    height: spacer(md);
    transform: translate(50%,-50%);
    z-index: 10;

    svg{
        stroke-width: 1;
    }
}

.chevronIcon{
    stroke-width: 1.5px;
    margin-left: spacer(xs);
    margin-bottom: spacer(3xs);
}