$env: production;
@import '@/styles/_component-base.styles.scss';

.container {
    min-height: 160px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    
    @include responsive-dpi-imgix('/extend/extend-on-ramp', 'extend-on-ramp', 375, 680, 1110);

    @include breakpoint(md) {
        min-height: 150px;
    }

    @include breakpoint(lg) {
        min-height: 200px;
    }

    &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(color(text, base), 0.2)
    }
}

.section {
    @include breakpoint(sm, max) {
        padding: 0
    }
}

.title {
    margin: spacer(md) auto spacer(sm);
    font-size: set-fontSize(24);
    max-width: 225px;

    @include breakpoint(md) {
        margin: spacer(sm) auto spacer(md);
        font-size: set-fontSize(28);
        max-width: 100%
    }

    @include breakpoint(lg) {
        margin: spacer(xl) auto spacer(md);
        font-size: set-fontSize(32);
    }
}

.button{
    max-width: 225px;
}