$env: production;
@import '@/styles/_component-base.styles.scss';

$accordion-list-max-width: 730px;
$accordion-list-margin-top-m: spacer(2xl);
$accordion-list-margin-top: spacer(3xl);

$accordion-list-items-margin-top: spacer(md) !default;

$accordion-content-font-size: 14px;

$accordion-item-padding:    spacer(md) !default;
$accordion-item-list-padding:    spacer(lg);
$accordion-item-margin:    spacer(md);
$accordion-item-border: solid 1px color(contrast,3) !default;
$accordion-item-icon-outline-offset: -.5px;

$accordion-heading-color-focus:     color(text, heading) !default;
$accordion-content-padding:    spacer(2xl) 0 spacer(md) !default;

$accordion-transition:                  all 500ms cubic-bezier(0.5, 0, 0.1, 1) !default;
$accordion-transition-content-close:    all 300ms cubic-bezier(0, 0, 0.25, 1) !default;
$accordion-transition-content-open:     all 300ms cubic-bezier(0.25, 0, 1, 1), opacity 300ms cubic-bezier(0.25, 0, 1, 1) 200ms !default;
$accordion-transition-icon:             all 250ms linear !default;

$disclaimer-padding: 0px 50px;

$detail-accordion-content-padding: spacer(base) 0 spacer(md) !default;
$bundle-accordion-content-padding: spacer(base) 0 spacer(sm) !default;

$accordion-typo: (
    'list-item-title': (
    'size':         16,
    'size-desktop':         16,
    'lineHeight':     1.75,
    'lineHeight-desktop':       1.75,
    'weight':       normal,
    'transform':    none,
    'marginBottom': spacer(xs)
    )
) !default;

@include makeTypoClasses($accordion-typo, '.');

.list-container {
    max-width: $accordion-list-max-width;
}

.list {
    margin-top: $accordion-list-margin-top-m;

    border-bottom: $accordion-item-border;

    @include media-query-up(md) {
        margin-top: $accordion-list-margin-top;
    }

    &--detail {
        margin-top: $accordion-list-items-margin-top;
        border-bottom: 0;
    }
}

.list-items {
    text-align: left;
}

.item {
    padding: $accordion-item-padding;
    transition: $accordion-transition;

    &--bgContrast1 {
        background: color(contrast, 1);
    }

    &--bgContrastWhite {
        background: color(contrast, white);
    }

    &:focus {
        outline: none;

        .icon {
            overflow: visible;
            outline-offset: $accordion-item-icon-outline-offset;
        }
    }

    &--detail {
        padding: spacer(base);
    }

    &--hidden {
        display: none;
    }

    .disclaimer {
        padding: $disclaimer-padding;
        font-style: italic;
    }
}

.heading {
    text-align: left;
    @include btn-reset;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    cursor: pointer;
    color: color(text,heading);
    outline: none;

    &:focus {
        color: $accordion-heading-color-focus;

        .icon {
            overflow: visible;
            outline-offset: $accordion-item-icon-outline-offset;
        }
    }

    &:active
    &:focus:active {
        color: color(text, active);
    }
}

.content {
    height: 0;
    visibility: hidden;
    opacity: 0;
    transition: $accordion-transition-content-close;
    margin-bottom: 0;

    &--defaultOpen {
        height: auto;
        visibility: visible;
    }
}

.icon {
    flex-shrink: 0;
    stroke: color(text, heading);
    stroke-width: 2px;
    margin-left: 5px;
    transition: $accordion-transition-icon;
}

.title {
    margin: 0;
}

.is-open {
    overflow: visible;
    animation: reveal 0 ease .01s;

    .content {
        visibility: visible;
        opacity: 1;
        transition: $accordion-transition-content-open;
    }
}

@keyframes reveal {
    from {
        overflow: hidden;
    }

    to {
        overflow: visible;
    }
}

.item {
    .contentPadding {
        padding: $accordion-content-padding;
    }
}

.item--detail {
    .contentPadding {
        padding: $detail-accordion-content-padding;
    }
}

.item--bundle {
    .contentPadding {
        padding: $bundle-accordion-content-padding;
    }
}

.item--noPadding {
    .contentPadding {
        padding: 0;
    }
}
