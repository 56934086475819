$env: production;
@import '@/styles/_component-base.styles.scss';

.bidirectionalTab {
    cursor: pointer;
}

.sliderBar {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 4px;
    background-color: color(text, base);
    transition: all 500ms ease;
}

.rightSetup {
    background-color: color(contrast, 2);

    .title {
        line-height: 1.2;
        font-weight: 300;
        text-transform: none;
        color: color(text, base);
        font-size: set-fontSize(24);
        font-family: $fontFamily-serif;

        @include breakpoint(lg) {
            font-size: set-fontSize(32);
        }
    }

    .accordionIcon {
        stroke: color(text, base);
    }

    .disclaimer {
        color: color(text, hover);
        letter-spacing: normal;
        font-size: set-fontSize(12);
        line-height: 1.6;
        font-weight: 400;
        text-transform: none;
        -webkit-font-smoothing: antialiased;
    }

    .infoPanel {
        height: fit-content;
        padding-bottom: spacer(2dot5xl);
        padding-top: spacer(md);

        @include breakpoint(lg) {
            padding-top: 0;
            padding-bottom: 0;
        }
    }

    .button {
        border-radius: 2px;
    }

    .subtitle {
        line-height: 1.5;

        &--bottomMargin {
            margin-bottom: spacer(2dot5xl);

            @include breakpoint(lg) {
                margin-bottom: spacer(3dot75xl);
            }
        }
    }

    .list {
        padding-left: spacer(md);
        list-style-type: disc;
    }

    .tooltipButton {
        right: unset;
        left: 106px;
        bottom: 4px;
        svg {
            height: 12px;
        }
    }

    .tooltipContent {
        max-width: none;
        top: 2px;
        width: 305px;
        right: unset;
        left: 18px;

        p {
            margin-top: 0px;
        }

        @include breakpoint(md){
            left: 60px;
        }

        @include breakpoint(lg){
            right: unset;
            left: -170px;
        }

        &::before, &::after{
            right: 200px;

            @include breakpoint(md){
                right: 242px;
            }

            @include breakpoint(lg){
                right: 12px;
            }
        }
    }

    .accordionContentPadding {
        padding: 0;
    }

    .accordionItem {
        padding: 0;
        margin: 0;
        border-bottom: 1px solid color(contrast, 3);

        &:first-child {
            border-top: 1px solid color(contrast, 3);
        }
    }

    .accordionTitle {
        letter-spacing: 3.5px;
    }

    .tabTitle {
        text-transform: uppercase;
    }

    .tabListScroll {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-bottom: spacer(lg);

        @include breakpoint(md) {
            margin-bottom: spacer(3xl);
        }
    }

    .tabList {
        border-bottom: solid 1px color(contrast, 3);
        justify-content: space-between;
        padding-bottom: spacer(2xs);
        display: flex;

        @include breakpoint(md) {
            margin-top: spacer(md);
            padding-bottom: spacer(sm);
        }
    }

    .tabName {
        text-align: left;
        color: color(text, recede);
        font-size: set-fontSize(14);
        font-weight: 300;
        letter-spacing: 3px;
        padding: 0 spacer(sm);
        text-transform: uppercase;

        &[aria-selected="true"] {
            color: color(text, base);
        }

        &:focus {
            outline: none;
        }

        @include breakpoint(lg) {
            padding: 0;
            letter-spacing: 4px;
            font-size: set-fontSize(16);
        }
    }

    .tabSlider {
        height: 1px;
        bottom: -1px;
        background-color: color(text, base);

        &--lg {
            height: 1.5px;
        }
    }

    .text {
        font-weight: 300;
        line-height: 1.56;
        letter-spacing: normal;
        font-size: set-fontSize(16);
        font-family: $fontFamily-sans-serif;

        @include breakpoint(lg) {
            font-size: set-fontSize(18);
        }
    }

    .imageContainer {
        display: flex;
        justify-content: center;

        @include breakpoint(md) {
            display: block;
        }
    }

    .image {
        width: 100%;
    }
}
