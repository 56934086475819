$env: production;
@import '@/styles/_component-base.styles.scss';

.section{
    justify-content: flex-start;
    align-items: flex-start;
    min-height: 350px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    @include responsive-dpi-imgix('/category/shop-the-room', 'shop-the-room', 375, 680, 1500);
    
    @include breakpoint(md) {
      min-height: 392px;
    }

    @include breakpoint(lg) {
      min-height: 572px;
    }

    &Content{
      padding-top: spacer(3xl);

      @include breakpoint(md){
        padding: spacer(lg) spacer(3xl)
      }

      @include breakpoint(lg){
        padding: spacer(2dot5xl) spacer(5xl)
      }
      @include breakpoint(xl){
        padding: spacer(2dot5xl) spacer(9xl)
      }
    }
}

.title{
    font-size: set-fontSize(28);
    text-align: center;


    @include breakpoint(md) {
      text-align: left;
      font-size: set-fontSize(36);
    }

    @include breakpoint(lg) {
      font-size: set-fontSize(40);
    }
}

.description {
    align-self: flex-start;
    margin: 0 auto;
    max-width: 480px;
    font-size: set-fontSize(16);
    text-align: center;

    @include breakpoint(md) {
      margin: 0;
      text-align: left;
    }
}

.ctasContainer{
    text-align: left;

    @include between-breakpoint(xs, sm) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
}

.button {
    max-width: 200px;
    color: white;
    border-color: white;
    background-color: rgba(0, 0, 0, 0.55);
    z-index: 1;

    @include between-breakpoint(xs, sm) {
      margin-top: 16px;
      margin-bottom: 0px;
    }

    @include breakpoint(md) {
      margin-right: spacer(sm);
      margin-left: spacer(sm);
    }

    @include breakpoint(lg) {
      margin-right: spacer(base);
      margin-left: spacer(base);
    }

    &:visited {
      color: white;
    }

    &:hover {
      background-color: rgba(0, 0, 0, 0.6);
    }
}


